<template>
    <div class="mypage_curation_send_wrap">
        <!--       받은 의뢰 리스트 레이아웃-->
        <table class="my_history_table" width="100%"  border="0" cellspacing="0" cellpadding="0"
               style = "table-layout: auto; width: 1200px; table-layout: fixed;">
            <colgroup>
                <col width="95px" />
                <col width="140px" />
<!--                <col width="120px" />
                <col width="120px" />
                <col width="120px" />
                <col width="120px" />
                <col width="120px" />
                <col width="120px" />
                <col width="120px" />
                <col width="120px" />-->
                <col width="140px" />
                <col width="140px" />
                <col width="140px" />
                <col width="140px" />
                <col width="140px" />
                <col width="" />
<!--                <col width="120px" />-->
                <col width="140px" />
            </colgroup>
            <tr class="title_row">
                <th colspan="1">No</th>
                <th colspan="1">{{$t('image')}}</th>
                <th colspan="1">{{$t('subject')}}</th>
                <th colspan="1">{{$t('client')}}</th>
                <th colspan="1">{{$t('status')}}</th>
                <th colspan="1">{{$t('selling_price')}}<br/>({{$t('max_bid')}})</th>
                <th colspan="1">{{$t('bid_history')}}</th>
                <th colspan="1">{{$t('r_date')}}</th>
<!--                <th colspan="1">{{$t('curation_abandonment')}}</th>-->
                <th colspan="1">{{$t('option')}}</th>
            </tr>
            <tr v-for="(curation, index) in curationData" :key="`curation${curation.ibc_idx}`">
                <td class="cursor" @click="moveItemDetail(curation)">{{ tableIdx(index) }}</td>  <!-- No -->
                <td class="cursor" @click="moveItemDetail(curation)">
                    <img :src="returnItemImage(curation)" width="78" height="49"/>
                </td>
                <td class="cursor t_subject" @click="moveItemDetail(curation)">{{ curation.i_name }}</td>  <!-- 아이템명 -->

                <td  class="cursor" @click="moveItemDetail(curation)">{{ curation.provider_nick }}</td>  <!-- 의뢰인 -->

                <td  class="cursor" @click="moveItemDetail(curation)">
                    {{ $t(returnStatusData(curation.ibc_status).showName) }}
                </td>

                <td  class="cursor" @click="moveItemDetail(curation)">{{ returnPrice(curation) }}</td> <!-- 최고입찰가 -->

                <td >
                    <button @click="openAuctionHistory(curation.m_idx)"  v-if="curation.m_type === 1">
                        {{$t('bid_history')}}
                    </button>
                    <span v-else>-</span>
                </td>

                <td  class="cursor" @click="moveItemDetail(curation)" v-html="returnCreatedAt(curation.created_at)"></td>

                <td>
                    <div v-if="returnStatusData(curation.ibc_status).hasCancel">
                        <button class="startBtn" @click="openAllowModal(curation)">{{$t('accept')}}</button>
                        <button class="endBtn ma5 ma-right-none" @click="rejectRequest(curation)">{{$t('refuse')}}</button>
                    </div>
                    <button class="endDate" v-else-if="hasCancel(curation)" @click="cancelRequest(curation)">
                        {{$t('curation_abandonment')}}
                    </button>
                    <button class="endDate" v-else-if="returnStatusData(curation.ibc_status).showMarketHistory" @click="$router.push(`/mypage/item/${curation.m_idx}/history`)">
                        {{$t('history_title')}}
                    </button>
                    <span v-else>{{returnDetailStatus(curation)}}</span>
<!--                    <button class="endDate" v-if="returnStatus(curation) === 'nosale'" @click="cancelRequest(curation)">
                        {{$t('curation_abandonment')}}
                    </button>
                    <button class="endDate" v-else-if="returnStatus(curation) === 'completed'" @click="$router.push(`/mypage/item/${curation.m_idx}/history`)">
                        {{$t('history_title')}}
                    </button>
                    <span v-else>-</span>-->
<!--                    <button class="endDate" @click="">
                        {{$t('status_change')}}
                    </button>-->
                </td>

            </tr>
        </table>

        <mypage-request-modal></mypage-request-modal>
    </div>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import dateMixins from "@/mixins/dateMixins";
import EventBus from "@/utils/event-bus";
import {curationStatus} from "@/service/curationService";
import MypageRequestModal from "@/components/modal/MypageRequestModal";

export default {
    name: "CurationListDefaultLayout",
    mixins: [alertMixins, imageOption, dateMixins],
    components: {MypageRequestModal},
    inject: [],
    provide() {
        return {}
    },
    props: {
        curationData: {default: () => []},
        paginationData: {default: {page: 1, size: 10, total: 0}},
    },
    data() {
        return {
            curationLayouts: 10,
            tempSubject: this.$t('temp_info')
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(`${this.$route.path}/${url}`)
        },
        tableIdx(index) {
            return this.paginationData.total - index - (this.paginationData.page - 1) * this.paginationData.size;
        },
        returnItemImage(curation) {
            if(util.isEmpty(curation.ItemFile) || util.isEmpty(curation.ItemFile.Item)) {
                return "";
            }
            return `${curation.ItemFile.Item[0].org_url}?${this.setImageOptions(78, 49, 'jpg')}`;
        },
        returnStatusData(value) {
            let status = {value: null, name: '-', showName: '-', hasCancel: false, showMarketHistory: false,};
            let index = curationStatus.findIndex(st => st.value === value);
            if(index > -1) {
                status = curationStatus[index];
            }
            return status;
        },
        hasCancel(curation) {
            let status = this.returnStatusData(curation.ibc_status);
            if(status.hasCancel) {
                return true;
            }
            return curation.ibc_status === 1 && curation.m_type == null;
        },
        returnPrice(curation) {
            let status = this.returnStatusData(curation.ibc_status);
            if((status.value == 4 || curation.i_status === 'pricetag' || curation.i_status === 'auction') && !isNaN(curation.i_price)) {
                return util.Number.numFormat(curation.i_price)
            }
            return '-'
        },
        returnCreatedAt(date) {
            return util.isEmpty(date) ? '-' : this.returnDateFormat(date, 'YYYY-MM-DD<br>HH:mm')
        },
        openAuctionHistory(m_idx) {
            this.$modal.show('auction-history-modal', {m_idx : m_idx})
        },
        moveItemDetail(curation) {
            let url = `/item/${curation.i_sku}`
            if(curation.im_status === 2 || curation.im_status === 5) {
                url = `/premiummarket/${curation.i_sku}`
            }else if(curation.im_status === 1 || curation.im_status === 3 || curation.im_status === 4) {
                url = `/openmarket/${curation.i_sku}`
            }
            this.$router.push(url);
        },
        returnDetailStatus(curation) {
            let status = this.returnStatusData(curation.ibc_status);
            if(status.value === 1 && curation.m_type == 1) {
                return this.$t('auction')
            }else if(status.value === 1 && curation.m_type == 3) {
                return this.$t('fix_selling')
            }
            return status.detailName === '-' ? '-' : this.$t(status.detailName)
        },
        acceptConfirm() {
            this.createConfirm({
                title: '',
                content: " '<span class='subject'>"+ this.tempSubject + "</span>'<br>" +
                    this.$t('mypage_accept_confirm')
                    +'<br>'+
                    this.$t('mypage_accept_confirm3'),
                confirmText : this.$t('yes'),
                cancelText : this.$t('cancel'),
                confirm: () => {

                },
                cancel: () => {

                }
            })
        },
        cancelRequest(curation) {
            this.createConfirm({
                title: '',
                content: " '<span class='subject'>"+ curation.i_name + "</span>'<br>" +
                    this.$t('mypage_cancel_confirm'),
                confirmText : this.$t('yes'),
                cancelText : this.$t('cancel'),
                confirm: () => {
                    // this.errorAlert('추후에 기능이 추가됩니다.')
                    EventBus.$emit('curationListDelete', curation.ibc_idx)
                },
            })
        },
        openAllowModal(curation) {
            this.$modal.show('mypage-request-modal', {curationData: curation});
        },
        rejectRequest(curation) {
            this.createConfirm({
                title: '',
                content: " '<span class='subject'>"+ curation.i_name + "</span>'<br>" +
                    this.$t('mypage_refuse_confirm'),
                confirmText : this.$t('yes'),
                cancelText : this.$t('cancel'),
                confirm: () => {
                    EventBus.$emit('curationListReject', curation);
                },
            })
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
