<template>
    <modal name="mypage-request-modal" class="mypage-modal nick"
           :width="`100%`" :height="`100%`" :pivotY="0.5"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div class="mypage-modal-wrap">
            <div class="top_wrap">
                <div class="modal-title"></div>
                <div class="close_btn_wrap request_modal">
                    <button class="close_modal" @click="modalClose()"></button>
                </div>
            </div>
            <div class="middle_wrap">
                '<div class='subject'>{{ curationData.i_name }}</div>' <br/>
                <div class="content" v-html="$t('mypage_accept_confirm')+'<br>'+$t('mypage_accept_confirm3')">
<!--                    {{$t('mypage_accept_confirm')}}<br/>

                    {{$t('in_process')}} <a @click="openModal('curation-terms-modal')">{{$t('terms_curation')}}</a>{{$t('mypage_accept_confirm2')}}-->
                </div>
                <div class="confirm_btn_wrap">
                    <button class="btn cancel ml0" @click="modalClose()">{{$t('cancel')}}</button>
                    <button class="btn save ml18" @click="runAllow">{{$t('yes')}}</button>
                </div>
            </div>
            <curation-terms-modal></curation-terms-modal>
        </div>
    </modal>
</template>

<script>
import EventBus from "@/utils/event-bus";
import CurationTermsModal from "@/components/modal/CurationTermsModal";
import util from "@/mixins/util";

export default {
    name: "MypageRequestModal",
    mixins: [],
    components: {
        CurationTermsModal,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            curationData: {},
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
    },
    methods: {
        handlerBeforeOpen(event) {
            if(util.isEmpty(event.params) || util.isEmpty(event.params.curationData)) {
                event.stop()
            }else{
                this.curationData = event.params.curationData
            }
        },
        handlerOpened() {
            $("body").addClass("modal-open")
            openTerms = this.openModal
        },
        handlerBeforeClose() {
            openTerms = null;
        },
        openModal(name) {
            this.$modal.show(name);
        },
        modalClose() {
            this.$modal.hide('mypage-request-modal');
            $("body").removeClass("modal-open")
        },
        runAllow() {
            this.modalClose();
            EventBus.$emit('curationListAllow', this.curationData);
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
